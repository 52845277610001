import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const HeaderTwo = () => {
  const [isAtTop, setIsAtTop] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  // Ekran boyutunu kontrol eden fonksiyon
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // 768px ve altındaki ekranlar mobil olarak kabul edilir
  };

  useEffect(() => {
    handleResize(); // İlk render sırasında ekran boyutunu kontrol et
    window.addEventListener("resize", handleResize); // Ekran boyutu değişikliklerini dinle

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY === 0); // Scroll durumunu kontrol et
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (isMobile) {
    // Mobil cihazlarda bileşen tamamen gizlenir
    return null;
  }

  const styles = {
    container: {
      display: "flex",
      height: "100vh",
      position: "fixed",
      zIndex: 9998,
    },
    sidebar: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "white",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      width: "80px",
      height: "100%",
      padding: "10px 0",
    },
    logo: {
      padding: "16px",
      transition: "all 0.5s ease-in-out",
      display: isAtTop ? "none" : "block",
    },
    logoImg: {
      height: "40px",
      width: "40px",
      zIndex: 10000,
    },
    navLinks: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 0",
    },
    navLink: {
      textAlign: "center",
      fontWeight: "bold",
      color: "black",
      borderRadius: "4px",
      transform: "rotate(-90deg)",
      width: "100%",
      height: "64px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textDecoration: "none", // Alt çizgiyi kaldırır
      transition: "background-color 0.3s ease",
    },
    navLinkActive: {
      backgroundColor: "#ffc107",
    },
  };

  return (
    <div style={styles.container}>
      <aside style={styles.sidebar}>
        <div style={styles.logo}>
          <img src="./favicon.png" alt="Logo" style={styles.logoImg} />
        </div>

        <nav style={styles.navLinks}>
          <NavLink
            to="https://ecowatt.io/"
            style={({ isActive }) =>
              isActive
                ? { ...styles.navLink, ...styles.navLinkActive }
                : styles.navLink
            }
          >
            HOME
          </NavLink>
          <NavLink
            to="https://ecowatt.io/ewx/"
            style={({ isActive }) =>
              isActive
                ? { ...styles.navLink, ...styles.navLinkActive }
                : styles.navLink
            }
          >
            EWX
          </NavLink>
          <NavLink
            to="https://ecowatt.io/projects/"
            style={({ isActive }) =>
              isActive
                ? { ...styles.navLink, ...styles.navLinkActive }
                : styles.navLink
            }
          >
            PROJECTS
          </NavLink>
          <NavLink
            to="https://ecowatt.io/contact/"
            style={({ isActive }) =>
              isActive
                ? { ...styles.navLink, ...styles.navLinkActive }
                : styles.navLink
            }
          >
            CONTACT
          </NavLink>
        </nav>
      </aside>
    </div>
  );
};

export default HeaderTwo;
