import React, { useEffect, useState, useMemo } from "react";
import info from "./info.js";
import { Card } from "react-bootstrap";
import axios from "axios";
import Loading from "./components/Loading";
import Bread from "./components/Bread";
import NormalInput from "./components/NormalInput";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import constants from "./constants.js";
import { toast } from "react-toastify";
import { useGlobalState } from "./hooks/useGlobalState.jsx";
import { useNavigate } from "react-router-dom";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { setCode } from "viem/actions";
import QRCode from "react-qr-code";

function Login() {
  const [key, setKey] = useState("home");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [passwordSecond, setpasswordSecond] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [loginError, setloginError] = useState("");
  const { globalState, setGlobalState } = useGlobalState();
  const navigate = useNavigate();
  const [init, setInit] = useState(false);
  const [forgotPassword, setforgotPassword] = useState(false);
  const [tfaState, settfaState] = useState(false);
  const [createState, setCreateState] = useState(false);
  const [otpURL, setotpURL] = useState();
  const [secret, setSecret] = useState();
  const [otpError, setotpError] = useState("");

  const [otp, setotp] = useState();
  const [token, settoken] = useState();

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options = useMemo(
    () => ({
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "repulse",
          },
        },
        modes: {
          push: {
            quantity: 2,
          },
          repulse: {
            distance: 100,
            duration: 0.4,
          },
        },
      },
      particles: {
        number: {
          value: 200,
        },
        color: {
          value: "#808080",
        },
        links: {
          enable: true,
          distance: 100,
        },
        shape: {
          type: "circle",
        },
        opacity: {
          value: 0.3,
        },
        size: {
          value: {
            min: 1,
            max: 3,
          },
        },
        move: {
          enable: true,
          speed: 2,
        },
      },
      detectRetina: true,
      fullScreen: { enable: false },
    }),
    []
  );

  const resetStates = () => {
    setemail("");
    setpassword("");
    setpasswordSecond("");
  };

  const resetErrors = () => {
    setEmailError("");
    setpasswordError("");
    setloginError("");
  };

  const switchTab = (e) => {
    resetStates();
    setKey(e);
  };

  useEffect(() => {
    resetStates();
    resetErrors();
  }, [forgotPassword]);

  const handleRegister = async (e) => {
    e.preventDefault();

    resetErrors();
    if (!email || !password) {
      toast.error("Please fill all required fields");
      return;
    }
    if (password !== passwordSecond) {
      setpasswordError("Passwords don't match!");
      return;
    }
    try {
      const res = await axios.post(`${constants.backend_url}/users/register`, {
        email: email,
        password: password,
      });
      console.log(res);
      if (res.status == 204) {
        //giriş yaptır onaylama ekranını göster
        handleLogin();
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.errors[0].extensions.field == "email") {
        console.log(error.response.data.errors[0].extensions.field);
        setEmailError("This email already in use.");
      }
    }
  };

  const handleLogin = async (e) => {
    if (e) e.preventDefault();

    setloginError(null); // Reset login error

    try {
      // Login request
      const loginResponse = await axios.post(
        `${constants.backend_url}/auth/login`,
        {
          email,
          password,
          otp,
        }
      );
      console.log(loginResponse);
      const token1 = loginResponse.data.data.access_token;
      console.log("token", token1);
      settoken(token1);

      // Fetch user items
      const response1 = await axios.get(
        `${constants.backend_url}/items/users`,
        {
          headers: {
            Authorization: `Bearer ${token1}`,
          },
        }
      );
      

      if (loginResponse.status === 200) {
        setGlobalState((prevState) => ({
          ...prevState,
          authToken: token1,
          user: response1.data.data[0],
        }));

        // Token'ı localStorage'a kaydetmek isterseniz:
        localStorage.setItem("authToken", token1);
      }

      navigate("/");
    } catch (loginError) {
      console.error("Login error:", loginError);
      if (loginError.response) {
        if (loginError.response.data.errors[0].extensions.code === "INVALID_OTP") {
          settfaState(true);
            if(otp){
                setotpError("Otp is invalid ");
            }
        } else {
          setloginError("Login failed. Please check your email and password!");
        }
      }
    }
  };


  const handleSendEmail = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please fill all required fields");
      return;
    }
    try {
      const res = await axios.post(
        `${constants.backend_url}/auth/password/request`,
        {
          email: email,
          reset_url: "https://wallet.ecowatt.io/reset-password",
        }
      );
      console.log(res);
      if (res.status == 204) {
        //giriş yaptır onaylama ekranını göster
        toast.success("Password reset instruction sent to your email.");
        resetStates();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Bread title={"EWX LOGIN"} />
      <div className="container mt-5 mb-5 ">
        <div className="row pb-5 ">
          <div
            className="col-md-6 mt-3 mb-3 px-4"
            style={{ position: "relative" }}
          >
            <div
              style={{
                position: "relative",
                zIndex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                padding: "20px",
              }}
            >
              <img src="/coin-img.png" className="img-fluid" alt="Coin Image" />
            </div>
            <div
              className=""
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                zIndex: 0,
                pointerEvents: "none",
              }}
            >
              <Particles id="tsparticles" options={options} height="1000px" />
            </div>

            {/* İçerik */}
          </div>

          <div className="col-md-6 mb-3 mt-3 px-4 d-flex flex-column justify-content-center align-items-center">
            <div
              className="row flex-grow-1"
              style={{
                border: "none",
                backgroundColor: "white",
                color: info.colors.first,
                borderRadius: "15px",
                padding: "20px",
              }}
            >
              <Card
                style={{
                  border: "none",
                }}
              >
                <Card.Body>
                  {!forgotPassword &&  (
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={switchTab}
                      className="mb-3"
                    >
                      {" "}
                      <Tab eventKey="home" title="Login">
                        <>
                          <form onSubmit={handleLogin}>
                            <div className="row mt-2">
                              <div className="col-3">
                                <hr
                                  className="lined"
                                  style={{
                                    float: "left",
                                    width: "100%",
                                    borderColor: info.colors.header,
                                  }}
                                />
                              </div>
                              <div className="col-6  text-center">
                                <h3>Login</h3>
                              </div>
                              <div className="col-3">
                                <hr
                                  className="lined"
                                  style={{
                                    float: "right",
                                    width: "100%",
                                    borderColor: info.colors.second,
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-12">
                                <p
                                  className="text-small"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Email
                                </p>
                                <NormalInput
                                  value={email}
                                  setValue={setemail}
                                  placeholder={"Your E-mail Address"}
                                  type={"email"}
                                  error={loginError}
                                />
                              </div>
                              <div className="col-12 mt-3">
                                <p
                                  className="text-small"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Password
                                </p>
                                <NormalInput
                                  value={password}
                                  setValue={setpassword}
                                  placeholder={"Your password"}
                                  type={"password"}
                                  error={loginError}
                                />
                              </div>
                              <div
                                className="col-12 mt-3"
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "center",
                                }}
                              >
                              {!tfaState && <button className="button-custom" type="submit">
                                  Login
                                </button>}
                              </div>
                            </div>
                          </form>
                        </>
                      </Tab>
                      <Tab eventKey="profile" title="Register">
                        <>
                          <div className="row mt-2">
                            <div className="col-3">
                              <hr
                                className="lined"
                                style={{
                                  float: "left",
                                  width: "100%",
                                  borderColor: info.colors.header,
                                }}
                              />
                            </div>
                            <div className="col-6  text-center">
                              <h3>Register</h3>
                            </div>
                            <div className="col-3">
                              <hr
                                className="lined"
                                style={{
                                  float: "right",
                                  width: "100%",
                                  borderColor: info.colors.second,
                                }}
                              />
                            </div>
                          </div>
                          <form onSubmit={handleRegister}>
                            <div className="row mt-3">
                              <div className="col-12">
                                <p
                                  className="text-small"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Email
                                </p>
                                <NormalInput
                                  value={email}
                                  setValue={setemail}
                                  placeholder={"Your E-mail Address"}
                                  type={"email"}
                                  error={emailError}
                                />
                              </div>
                              <div className="col-12 mt-3">
                                <p
                                  className="text-small"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Password
                                </p>
                                <NormalInput
                                  value={password}
                                  setValue={setpassword}
                                  placeholder={"Your password"}
                                  type={"password"}
                                  error={passwordError}
                                />
                              </div>
                              <div className="col-12 mt-3">
                                <p
                                  className="text-small"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Re-type Password
                                </p>
                                <NormalInput
                                  value={passwordSecond}
                                  setValue={setpasswordSecond}
                                  placeholder={"Password again"}
                                  type={"password"}
                                  error={passwordError}
                                />
                              </div>
                              <div
                                className="col-12 mt-3"
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "center",
                                }}
                              >
                                <button className="button-custom" type="submit">
                                  Register
                                </button>
                              </div>
                            </div>
                          </form>
                        </>
                      </Tab>
                    </Tabs>
                  )}
                  {forgotPassword ? (
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => setforgotPassword(false)}
                    >
                      Login
                    </a>
                  ) : (
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => setforgotPassword(true)}
                    >
                      Forgot Password?
                    </a>
                  )}
                  {tfaState && !forgotPassword  &&(
                    <div
                      className="tfa-container"
                      style={{
                        border: "2px solid #ddd",
                        borderRadius: "10px",
                        padding: "20px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        width: "100%",
                        margin: "20px auto",
                        textAlign: "center",
                      }}
                    >
                      <h4 style={{ color: "#333", marginBottom: "15px" }}>
                        Two-Factor Authentication
                      </h4>
                      <div style={{textAlign:"left"}}>
                      <NormalInput
                        value={otp || ""}
                        setValue={setotp}
                        placeholder={"Enter OTP"}
                        type={"text"}
                        error={otpError}
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          padding: "10px",
                          width: "100%",
                          marginBottom: "30px",
                          backgroundColor: "#f9f9f9",

                        }}
                      />
       
                      </div>
                      <button
                        onClick={handleLogin}
                        className="button-custom"
                        style={{marginTop:"10px"}}
                      >
                        Submit
                      </button>
                     
                    </div>
                  )}

                  {forgotPassword && (
                    <>
                      <>
                        <div className="row mt-2">
                          <div className="col-3">
                            <hr
                              className="lined"
                              style={{
                                float: "left",
                                width: "100%",
                                borderColor: info.colors.header,
                              }}
                            />
                          </div>
                          <div className="col-6  text-center">
                            <h3>Forgot Password</h3>
                          </div>
                          <div className="col-3">
                            <hr
                              className="lined"
                              style={{
                                float: "right",
                                width: "100%",
                                borderColor: info.colors.second,
                              }}
                            />
                          </div>
                        </div>
                        <form onSubmit={handleSendEmail}>
                          <div className="row mt-3">
                            <div className="col-12">
                              <p
                                className="text-small"
                                style={{ fontWeight: "bold" }}
                              >
                                Email
                              </p>
                              <NormalInput
                                value={email}
                                setValue={setemail}
                                placeholder={"Your E-mail Address"}
                                type={"email"}
                                error={emailError}
                              />
                            </div>

                            <div
                              className="col-12 mt-3"
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <button className="button-custom" type="submit">
                                Reset Password
                              </button>
                            </div>
                          </div>
                        </form>
                      </>
                    </>
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
